.field-mr {
  margin-top: 27px;
}

.field-text-mr {
  font-size: 14px;
  color: #19171a;
  font-weight: bold;
  margin-bottom: 11px;
}

.field-text {
  font-size: 14px;
  color: #19171a;
  font-weight: bold;
}

.field-input input {
  height: 48px;
  width: 100%;
  border: 1px solid #9e9e9e;
  font-size: 14px;
  font-family: Scandia;
  box-sizing: border-box;
  padding-left: 20px;
}

.field-input textarea {
  border: 1px solid #9e9e9e;
  font-size: 14px;
  width: 100%;
  font-family: Scandia;
  box-sizing: border-box;
  padding-left: 20px;
  padding-top: 10px;
}

.field-input input:focus,
.field-input textarea:focus {
  border: 2px solid #1a171b;
  outline: none;
}

.field-input textarea:hover {
  border: 2px solid #1a171b;
  outline: none;
}

.field-input input:hover {
  border: 2px solid #1a171b;
  outline: none;
}

.field-input .error {
  border: 2px solid #c00;
}

.field-input .success {
  border: 2px solid #69cc9a;
}

.field-input__validation-error-box {
  opacity: 0;
  height: 20px;
  width: 20px;
  background-color: #c00;
  position: relative;
  float: right;
  right: -8px;
  top: -34px;
  transition: 0.2s ease-out;
  display: block;
}

.field-input__validation-box {
  color: white;
  text-align: center;
}

.field-input__validation-box svg {
  position: relative;
  top: -0.5px;
}

.field-input__validation-success-box {
  opacity: 0;
  height: 20px;
  width: 20px;
  background-color: #69cc9a;
  position: relative;
  float: right;
  right: -8px;
  top: -34px;
  transition: 0.2s ease-out;
  display: block;
}

.error ~ .field-input__validation-error-box {
  opacity: 1;
  transition: 0.2s ease-out;
  right: 8px;
}

.success ~ .field-input__validation-success-box {
  background-color: #69cc9a;
  opacity: 1;
  transition: 0.2s ease-out;
}

.field-input {
  position: relative;
}

.field-input__prefix {
  position: absolute;
  top: 13px;
  left: 9px;
}

.field-input input[type="textarea"] {
  height: 174px;
  word-break: break-word;
}

.field-input__validation-error {
  margin-top: 10px;
  right: 0px;
  color: #c00;
  font-size: 0.875rem;
  display: block;
}

.step2-content-text {
  margin-top: 10px;
  font-size: 0.875rem;
  text-align: justify;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-ms-clear {
  display: none;
}
