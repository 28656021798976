.confirmation__review, .pdf__review {
    border: 1px solid #e4e4e4;
}

.confirmation__review-inner {
    padding: 25px 35px 25px 35px;
}

.pdf__review-inner{
    padding: 25px 35px 25px 35px;
}

.confirmation__email-button, .confirmation__post-button {
    margin-top: 12px;
}

.confirmation__email-button.button, .confirmation__post-button.button  {
    max-width: 75%;
}

.confirmation__pdf-button.button{
    width: 87%;
    color: #747070;
}

.confirmation__offer-success {
    color: #19171A;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 0;
}

.confirmation__offer-check{
    margin-top: 24px !important;
}

.confirmation__offer-check-icon {
    color:white;
    background-color: #CC0066;
}

.confirmation__offer__check{
    color: white;
    font-size: 70px;
    font-size: 70px;
    margin-top: 13px;
    margin-left: 13px;    
}

.confirmation__offer__squareBox {
    height: 96px;
    width: 96px;
    background-color: #CC0066;
    margin-left: 15px;
}

.mortgage__approve__h4{
    color: #CC0066;
    font-family: Scandia;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
}

.mortgage__approve__h5{
    color: #CC0066;
    font-family: Scandia;
    font-style: normal;
    font-weight: bold;
}

.col-approve{
    margin-top: 15px;
    margin-right: 15px;
    padding-left: 15px;
}

.small-loader-confirmation {
    position: absolute;
    left: 17rem;
    top: 59.8%;
    width: 11.5%;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    div.pdf__review.row > div > div > div.button__next{
        right: 80px;
    }
  }

  @media screen and (max-width: 400px) {
    .small-loader-confirmation {
        left: 13rem;
        top: 66.8%;
        width: 12.5%;
    }
  }