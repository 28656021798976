.current-mortgage {
    border: 1px solid #e4e4e4;
}

.current-mortgage__inner {
    padding: 25px 35px;
}

.current-mortgage__information {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
}

.current-mortgage__valuation {
    background-color: #f4f4f4;
    padding: 0 35px 25px;
}

@media(max-width: 576px){
    .current-mortgage__information {
        right: 1rem;
    }
}