.thankyoufooter {
  width: 100%;
  background-color: #294952;
  margin-top: 24px;
  color: #e4e4e4;
  position: absolute;
  bottom: 0px;
}

.footer__content {
  max-width: 75%;
  margin: auto;
}

.footer__link:not(:last-chil d):before {
  content: "";
  position: absolute;
  left: 100%;
  height: 60%;
  border-right: 2px solid white;
}

.footer__option {
  flex-basis: auto !important;
}

.sbLogo {
  padding: 20px;
}

.logoImage {
  max-width: 300px;
  max-height: 60px;
}

.thanksText {
  position: absolute;
  left: 30%;
  top: 35%;
  color: #19171a;
  font-weight: bold;
  letter-spacing: 1px;
  min-width: auto;
  text-align: justify;
  text-align-last: center;
}

.pText {
  color: gray;
  font-size: 13pt;
  font-weight: bold;
  margin-top: 25px;
  text-align: justify;
  text-align-last: center;
}

@media screen and (max-width: 800px) {
  .left,
  .main,
  .right {
    width: 100%; /* The width is 100%, when the viewport is 800px or smaller */
  }
}
