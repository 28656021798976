.navFooter {
  position: relative;
  bottom: 0px;
  height: 80px;
  background-color: #f4f4f4;
  margin-top: 40px;
  width: 100vw;
  left: 49%;
  right: 0;
  margin-bottom: -24px;
  overflow: hidden;
  display: block !important;
  margin-left: -50vw;
}
.contbtn {
  text-align: left;
  padding-left: 15px;
  width: 275px;
}
.button__main {
  justify-content: normal;
  padding-left: 15px;
  margin-right: 0;
}

.back-button-nav {
  color: #787878;
  font-weight: bold;
  margin-top: 13px;
}

.button__disabled {
  background-color: #b4b4b4 !important;
  color: #787878 !important;
}

.button__next-disabled {
  background-color: #b4b4b4;
}

.button__next-icon-disabled {
  color: #787878;
  text-align: center;
  position: relative;
  top: 6px;
  left: 1px;
  font-size: 26px;
}

.disabled-link {
  pointer-events: none;
}

.nav-footer-row {
  bottom: 0px;
}
.nav-align {
  float: right;
  margin-right: 20px;
  margin-top: 20px;
}
