.product-search__avm-valuation-container {
    width: 100%
}

.product-search__avm-valuation {
    width: 50%;
    float: left;
}

.product-search__desired-loan {
    width: 50%;
    float: right;
}

.product-search__effective-ltv {
    margin-top: 72px;
}

.product-search__results {
    clear: both;
}

.product-search__matching-products {
    color: #cc0066;
}