@font-face {
  font-family: Scandia;
  src: url("./fonts/ScandiaWebRegular.eot");
  src: url("./fonts/ScandiaWebRegular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ScandiaWebRegular.woff2") format("woff2"),
    url("./fonts/ScandiaWebRegular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: Scandia;
  src: url("./fonts/ScandiaWebBold.eot");
  src: url("./fonts/ScandiaWebBold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ScandiaWebBold.woff2") format("woff2"),
    url("./fonts/ScandiaWebBold.woff") format("woff");
  font-weight: 700;
}

html {
  scroll-behavior: smooth;
}

.App {
  font-family: "Scandia", Arial, sans-serif;
}

.page-content {
  margin-top: 24px;
}

.main-area {
  min-height: 90vh;
}

.no-decor {
  text-decoration: none !important;
}

h2 {
  font-size: 37px;
  color: #19171a;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
}

h4 {
  font-size: 23px;
  margin: 0;
}

h5 {
  font-size: 20px;
  font-weight: bold;
  color: #19171a;
  margin: 0;
}

h6 {
  font-size: 16px;
  color: #19171a;
  letter-spacing: 0.83px;
}

p {
  font-size: 13px;
  letter-spacing: 1px;
  color: #1a171b;
}

.text--bold {
  font-weight: bold;
}

a:hover {
  text-decoration: none;
}

.warning {
  color: red;
}

.margin-top {
  margin-top: 30px;
}

@media (min-width: 992px) {
  .container {
    max-width: 934px;
  }
}

@media (max-width: 767px) {
  .register {
    margin-bottom: 48px;
  }
  .account-information {
    margin-bottom: 16px;
  }
}

@media (max-width: 576px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
  .login__box {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.box-height {
  min-height: 550px;
  max-height: auto;
  height: 100%;
}
