.information-icon {
    height: 25px;
    width: 25px;
    text-align: center;
    line-height: 23px;
    font-size: 16px;
    border: 2px solid #294952;
    color: #294952;
    cursor: pointer;
    font-weight: bold;
    z-index: 5;
    transition: 0.1s ease-out;
    outline: none;
}


.information-icon.inverted {
    border: 2px solid white;
    color: white;
}

.modal-content {
    border: 5px solid #294952 !important;
    border-radius: 0 !important;
}

.modal-header, .modal-footer {
    border: none !important;
}

.information-icon:hover {
    transform: scale(1.1);
    transition: 0.1s ease-out;
    outline: none;
}

.modal-backdrop{
    background-color: black;
    opacity: 0.05 !important;
  }

