.page-introduction {
    left: 0px;
    width: 100%;
}

.page-introduction h2 {
    margin-top: 0px;
}

.page-introduction h6 {
    height: 22px;
    margin: 0px;
}
