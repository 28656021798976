.step2Label {
  left: 0px;
  top: 1px;
  bottom: 16px;
  font-family: Scandia;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #19171a;
  margin-top: 60px;
}

.step2label-row {
  background: #ffffff;
  border-bottom: 1px solid #979797;
  width: 100%;
  margin-left: unset;
}

.question {
  width: 445px;
  top: 1px;
  bottom: 16px;
  font-family: Scandia;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  letter-spacing: 1px;
  color: #19171a;
  padding-left: 0px;
}

.answer {
  top: 1px;
  bottom: 16px;
  font-family: Scandia;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  letter-spacing: 0.83px;
  color: #19171a;
  margin-left: unset;
  padding-top: 15px;
  padding-bottom: 15px;
}

.address{
  font-family: Scandia;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.83px;
  color: #19171a;
  margin-left: unset
}

.address-col{
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 16px;
}

.page-break {
  page-break-after: always;
}
