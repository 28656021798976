.button {
    height: 48px;
    width: 100%;
}

.button:hover {
    cursor: pointer;
}

.button__main {
    position: relative;
    right: 48px;
    width: calc(100% - 53px);
    margin-right: 5px;
    font-size: 14px;
    letter-spacing: 0.83px;
    font-weight: bold;
    line-height: 19px;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
}

.button.primary .button__main {
    background-color: #cc0066;
    color: white;
}

.button.inactive {
    cursor: not-allowed;
}

.button.inactive .button__main {
    background-color: #e4e4e4;
    color: white;
}

.inactive ~ .button__next {
    background-color: #e4e4e4 !important;
}

.inactive ~ .button__download {
    background-color: #e4e4e4 !important;
}

.button.secondary .button__main {
    width: 100%;
    right: 0;
    margin-right: 0;
    background-color: white;
    border: 1px solid #cc0066;
    color: #cc0066;
}

.button__next{
    position: absolute;
    background-color: #cc0066;
    height: 48px;
    width: 48px;
    box-sizing: unset;
}

.button__download {
    position: absolute;
    background-color: #cc0066;
    height: 48px;
    width: 48px;
    box-sizing: unset;
}

.button__next-icon{
    color: white;
    text-align: center;
    position: relative;
    top: 6px;
    left: 1px;
    font-size: 26px;
}

.button__download-icon {
    color: white;
    text-align: center;
    position: relative;
    top: 8px;
    left: 1px;
    font-size: 22px
}

.button.secondary .button__next{
    background-color: white;
    border: 1px solid #cc0066;
    color: #cc0066;
}

.button.secondary .button__download{
    background-color: white;
    border: 1px solid #cc0066;
    color: #cc0066;
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .button__next{
        position: absolute;
        right: 20px;
        background-color: #cc0066;
        height: 48px;
        width: 48px;
        box-sizing: unset;
    }
    .button__download{
        position: absolute;
        right: 20px;
        background-color: #cc0066;
        height: 48px;
        width: 48px;
        box-sizing: unset;
    }
}