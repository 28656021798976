.timeline__tickbox {
    height: 28px;
    width: 28px;
    background-color: #c06;
    z-index: 2;
    position: relative;
    left: 14px;
}

.timeline__tickbox--unchecked {
    height: 28px;
    width: 28px;
    position: relative;
    left: 14px;
    z-index: 2;
    background-color: #fff;
}

.timeline__outer {
    border: 1px solid #e4e4e4;
    background-color: #e4e4e4;
}

.timeline__inner {
    padding: 25px 35px 25px 35px;
    width: 100%;
}

.timeline__check-icon {
    color: white;
    font-size: 20px;
}

.timeline__bar {
    height: 100%;
    width: 0px;
    position: absolute;
    top: 0px;
    left: 23px;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 1;
    border-left: 5px solid #fff;
    border-right: 5px solid #fff;
}

.timeline__guarantors p {
    color: #c06 !important;
}

@media (max-width: 936px) {
    .timeline__tickbox {
        left: 9px;
    }
    .timeline__tickbox--unchecked {
        left: 9px;
    }
    .timeline__check-icon{
        font-size: 20px;
    }
    .timeline__bar {
        left: 18px;
    }
}