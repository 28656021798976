.product-card {
  background-color: #632550;
  min-height: 100px;
  min-width: 100px;
  margin-bottom: 25px;
  color: #ffffff !important;
}

.selected-product-card {
  background-color: #294952;
  min-height: 100px;
  min-width: 100px;
  margin-bottom: 25px;
  color: #ffffff !important;
}

.product-card h2,
.product-card p {
  color: #ffffff !important;
  margin: 0px;
}

.selected-product-card h2,
.selected-product-card p {
  color: #ffffff !important;
  margin: 0px;
}

.product-card__select-product {
  margin-top: 12px;
}

.product-card__selected-product {
  margin-top: 12px;
}

.product-card__inner {
  padding: 25px 35px 25px 35px;
}

.product-card__divider {
  height: 0px;
  width: 100%;
  border-bottom: 1px solid white;
}

.product-card__information {
  background-color: #e4e4e4;
}

.product-card__information p {
  color: #19171a !important;
  position: relative;
}

@media (max-width: 567px) {
  .product-card__information p {
    letter-spacing: 0;
  }
}

.product-card__information .information-icon {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-52%);
}
