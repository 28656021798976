.message-information-field {
    margin-top: 15px;
}

.message-information-field__label {
    color: #19171a;
    font-weight: bold;
    margin: 0;
}

.message-information-field__value {
    font-size: 18px;
    color: #cc0066;
    margin: 0;
    word-wrap: break-word;
}

.secondary .message-information-field__value {
    font-size: 22px;
    color: #19171a;
}

.message-information-field.inverted .message-information-field__label {
    color: white;
}

.message-information-field.inverted .message-information-field__value {
    color: white;
}

.message-icon {
    height: 22px;
    width: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 23px;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    z-index: 5;
    transition: 0.1s ease-out;
    outline: none;
    
}

.message-margin{
    margin-top: 0.5em;
    margin-bottom: -1em;
}
