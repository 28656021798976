.header {
  width: 100%;
  top: 0px;
  left: 0px;
  height: 62px;
  box-sizing: border-box;
  border-bottom: 5px solid #cc0066;
  background-color: #ffffff;
  z-index: 100;
}

.header__content {
  height: 100%;
  position: relative;
}

.header_drop-down {
  position: absolute;
  z-index: 2;
  top: 56px;
  right: 16px;
  background: #fff;
  border: 1px solid #fafafa;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 3s;
  display: block;
  cursor: default;
}

.header_drop-down li {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
  list-style: none;
}

.header_drop-down li:hover {
  background-color: lightgray;
}

.header_drop-down li:last-child {
  border-bottom: none;
}

.header__content .logo {
  position: absolute;
  top: 8px;
}

.date {
  float: right;
  background-color: transparent;
  border: none;
  color: #19171a;
  font-weight: bold;
  position: relative;
  height: 30px;
  top: calc(70% - 15px);
}

.header__sign-out-button {
  float: right;
  background-color: transparent;
  border: none;
  color: #19171a;
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 38px;
  cursor: default;
  top: 15px;
  transition: all 10s ease-out;
}

*:focus {
  outline: 0 !important;
}

@media screen and (max-width: 1284px) {
  .header__content {
    width: 936px;
  }
}

@media (max-width: 946px) {
  .header__content {
    width: 90%;
  }
}

.arrow {
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg) !important;
}

.arrow {
  margin-left: 15px;
  color: #cc0066;
  font-size: 18px;
  display: inline-block;
  position: relative;
}

.sigoutLabel {
  position: relative;
  top: 2px;
}

#dropDownList {
  height: 0;
  opacity: 0;
  transition: all 0.5s linear;
  overflow: hidden;
  width: 155px;
  margin: 0px;
  padding: 0px;
}

#dropDownList.showDropDown {
  animation: fadein 0.5s linear;
  opacity: 1;
  height: 61px;
  border: 1px solid #dddddd;
}

#dropDownList.hideDropDownHide {
  animation: fadeout 0.5s linear;
}

@keyframes fadein {
  from {
    height: 0px;
  }
  to {
    height: 60px;
  }
}

@keyframes fadeout {
  from {
    height: 60px;
    border: 1px solid rgba(221, 221, 221, 1);
  }
  to {
    height: 0px;
    border: 1px solid rgba(221, 221, 221, 0);
  }
}

#dropDownList li {
  list-style: none;
  padding: 2.5px 10px;
  border-bottom: 1px solid #dddddd;
}

#dropDownList li:last-child {
  border: none;
}
