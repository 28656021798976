.step1__continue {
  padding-left: 2rem;
}

.personal-details {
  border: 1px solid #e4e4e4;
}

.account-information {
  border: 1px solid #e4e4e4;
}

.row {
  display: flex; /* equal height of the children */
}

.col {
  flex: 1; /* additionally, equal width */
}

@media (max-width: 767px) {
  .step1__continue {
    padding-left: 0rem;
  } 

}

.progress{
  background: #F8F8F8; 
  border-radius: 0px; 
  height: 14px;
  margin-left: -15px;
  margin-right: -15px;
}

.progress-bar{
  background-color: #294952;

}

@media (min-width: 767px) and (max-width: 992px) {
  .step1__continue {
    padding-left: 0.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .col { 
    display: block;
    width: 100%;
  } 
   .col-1 {
     display:none;
  }

}
