.personal-details {
    border: 1px solid #e4e4e4;
    
}

.personal-details__inner {
    padding: 25px 35px;
}

.brokerLabel {
    padding-top: 20px;
}

.personal-details__main-information {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
}

@media(max-width: 576px){
    .personal-details__main-information {
        right: 1rem;
    }
}
