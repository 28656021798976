.card-title {
  font-size: 14px;
}

.card-subtitle {
  font-family: Scandia;
  font-size: 16px;
  line-height: 21px;
  font-weight: lighter;
}
.table {
  overflow: visible;
  position: relative;
  border: 1px solid #294952;
  margin: 0px 1px 0px 1px;
  border-collapse: collapse;
}

.home-icon {
  display: block;
  margin: auto;
  width: 70%;
  padding-top: 10px;
  margin-right: 10px;
}

.table-br {
  border: 1px solid #ccc;
}

.table th {
  margin-right: 20px;
  padding: 0rem;
  vertical-align: top;
  border-top: 1px solid #294952;
  overflow: visible;
  font-size: 13px;
  font-weight: normal;
}

.table thead th {
  vertical-align: top;
  border-bottom: none;
  padding: 25px 13px 10px 0;
}
.th-vertical-align {
  vertical-align: middle !important;
  width: 70%;
  padding-top: 12px !important;
}

.table td {
  vertical-align: middle;
}

.box {
  border: 1px solid #294952;
  box-sizing: border-box;
  width: 100%;
}

.colgrid {
  align-self: center;
  align-content: center;
}

td.btn-mr {
  padding-left: 10px;
}

.button-to-select {
  color: white;
  background-color: #cc0066;
  border: none;
  justify-content: center;
  border-radius: unset;
  padding-top: 4px;
  height: 45px;
  width: 115px;
  font-weight: 600;
  padding-top: 0.5rem;
}

div.new-cases {
  width: auto;
  background-color: rgb(97, 162, 161);
  color: white;
  padding-left: 15px;
  font-weight: bold;
  margin-left: -14px;
  margin-right: -14px;
  margin-top: 15px;
  padding-top: 4px;
  border:.5px solid #294952;
  border-bottom: none;
}

div.inprogress-cases {
  width: auto;
  background-color: rgb(99, 37, 80);
  color: white;
  padding-left: 15px;
  font-weight: bold;
  margin-left: -14px;
  margin-right: -14px;
  margin-top: 15px;
  padding-top: 4px;
  border:.5px solid #294952;
  border-bottom: none;
}

div.expired-cases {
  width: auto;
  background-color: gray;
  color: white;
  padding-left: 15px;
  font-weight: bold;
  margin-left: -14px;
  margin-right: -14px;
  margin-top: 15px;
  padding-top: 4px;
  border:.5px solid #294952;
  border-bottom: none;
}

.table-row-mr{
  margin-bottom: 4px;
}

.client-mr{
  min-width: 150px;
  max-width: 150px;
}

.address-mr{
  min-width: 175px;
  max-width: 175px;
}

.case-mr{
  min-width: 150px;
  max-width: 150px;
}

.date-mr{
  min-width: 150px;
  max-width: 150px;
}

.table-scroll {
  overflow: auto;
}

@media only screen and (max-width: 800px) and (min-width: 320px) {
  .table-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){
	.row {
	margin-right:-13px;
	}
}
