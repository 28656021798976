.step8__contact-options_broker {
    padding-left: 25px;
    border-left-style: solid;
    border-color: #bfb9b9;
  }
  
  .step8__contact-options_broker_confirmation {
    padding: 0.5px 0 145px 0;
  }

  .checkboxLeft {
      float: left;
      margin-left: 15px;
  }
  
  .subHeading {
    font-weight: bold;
    margin-left: -15px;
    margin-top: 15px;
  }

  .declaration {
    padding-top: 10px;
    text-align: justify;
    margin-left: 73px;
    margin-right: -15px;
  }

  
@media (min-width: 992px) {
    .container {
      max-width: 934px;
    }
  }

  
@media (min-width: 320px) and (max-width:379px)
{
.step8__contact-options_broker {
      height: 203px;
    }
}

@media (min-width: 380px) and (max-width:768px)
{
.step8__contact-options_broker {
      height: 190px;
    }
}


@media (min-width: 768px) and (max-width:991px)
{
.step8__contact-options_broker {
      height: 205px;
    }
}
