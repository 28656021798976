
.field {
    margin-top: 20px;
}

.field-text {
    font-size: 14px;
    color: #19171a;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.checkbox-select__option {
    margin-top: 19px;
    height: 31px;
    font-size: 14px;
}

.checkbox-select__label {
    font-weight: normal;
    color: #1a171b;
}

.checkbox-select__input-box {
    float: right;
    height: 31px;
    width: 31px;
    border: 1px solid #9e9e9e;
    box-sizing: border-box;
}

.checkbox-select__check-icon {
    margin: 0px;
    color: white !important;
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}

.checkbox-select__input-box.selected {
    background-color: #cc0066;
    border: 0px;
}

@media(max-width: 767px){
    .checkbox-select__option {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .checkbox-select__input-box {
        margin-top: 10px;
        margin-left: 4px;
        flex-shrink: 0;
    }
}

@media(max-width: 1285px){ 
    .checkbox-select__label {
        font-size: 13px;
        line-height: 13px;

    }
}