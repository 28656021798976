.step7__contact-options_broker {
  padding-left: 25px;
  border-left-style: solid;
  border-color: #bfb9b9;
}

.step7__contact-options_broker_confirmation {
  padding: 0.5px 0 145px 0;
}

.step7__contact-options_broker .checkbox-select__label, .step7__contact-options_broker_confirmation .checkbox-select__label {
  letter-spacing: 1px;
  font-size: 13px;
}

@media (min-width: 320px) and (max-width:379px)
{
.step7__contact-options_broker {
      height: 203px;
    }
}

@media (min-width: 380px) and (max-width:768px)
{
.step7__contact-options_broker {
      height: 190px;
    }
}


@media (min-width: 768px) and (max-width:991px)
{
.step7__contact-options_broker {
      height: 205px;
    }
}

