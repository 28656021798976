.referral__review {
    border: 1px solid #e4e4e4;
    padding-left: 0px;
    padding-right: 0px;
}

.referral__review_col {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -5px;
} 

.referral__review-inner{
    padding: 25px 35px 25px 35px;
}
.referral__pdf-button{
    width: 80%;
    color: #747070;
}

.pdf__validation-error {
    margin-top: 10px;
    right: 0px;
    color: #c00;
    font-size: 0.875rem;
    display: block;
    margin-left: 6px;
}

.referral__contact-us{
    padding-right: 0px;
    padding-left: 30px;
}

.small-loader {
    position: absolute;
    left: 16rem;
    top: 0.5%;
    width: 12%;
  }

.button.inactive .button__main {
    background-color: #e4e4e4;
    color: #747070 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    div.referral__review.col-12.col-md-6 > div > div > div > div.button__next {
        right: 80px;
    }
    .small-loader {
        left: 15rem;
      }    
}

@media screen and (max-width: 400px) {
    .small-loader {
        left: 9rem;
        top: 0.7%;
        width: 17%;
    }
    .button__main{
        text-overflow: ellipsis;
        white-space: nowrap;
      }
  }

  .capitalRaise{
      color: #cc0066;
  }

  .capitalRaisespan{
      color: black;
  }

.capitalRaiseList{
  list-style-type: square;
  margin-top: -15px;
  margin-left: -15px;
  font-size: 13px;
  letter-spacing: 1px;
}