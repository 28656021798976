.product-compare__important-information, .product-compare__important-tc {
    margin-top: 30px;
    box-sizing: border-box;
    display: inline-block;
}

.product-compare__important-information__inner, .product-compare__important-tc__inner {
    padding: 25px 35px 25px 35px;
    border: 1px solid #e4e4e4;
}

.product-compare__important-information__inner h5, .product-compare__important-tc__inner h5 {
    margin: 0;
    margin-top: 5px;
}

.product-compare__important-information__inner p, .product-compare__important-tc__inner p {
    color: #1a171b;
}

.product-compare__continue {
    position: relative;
    left: 14px;
}

.product-compare__continue .button__main {
    width: calc(100% - 25px);
}

@media(max-width: 767px){
    .product-compare__important-tc__inner {
        padding-bottom: 60px;
    }
}

.step7__buttons .step7__download {
    margin-bottom: 1em;
    float: right;
}

.step7__buttons .step7__continue {
    float: right;
}

.step7__buttons .step7__download .button__main {
    background-color: #e4e4e4;
    color: #787878;
    border: none;
}

