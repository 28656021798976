.loader {
    height: 200px;
    width: 200px;
  }
  
  .circ {
    position: absolute;
    height: 10px;
    width: 10px;
    background: #c06;
    border-radius: 50%;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
  
  .circ1 {
    transform: scale(1) translateY(-50px);
    animation: circGrow1 1.4s ease-out infinite;
  }
  .circ2 {
    transform: scale(1) rotate(45deg) translateY(-50px);
    animation: circGrow2 1.4s ease-out infinite;
    animation-delay: 0.2s
  }
  .circ3 {
    transform: rotate(90deg) translateY(-50px);
    animation: circGrow3 1.4s ease-out infinite;
    animation-delay: 0.2s;
  }
  .circ4 {
    transform: rotate(135deg) translateY(-50px);
    animation: circGrow4 1.4s ease-out infinite;
    animation-delay: 0.4s;
  }
  .circ5 {
    transform: rotate(180deg) translateY(-50px);
    animation: circGrow5 1.4s ease-out infinite;
    animation-delay: 0.6s;
  }
  .circ6 {
    transform: rotate(225deg) translateY(-50px);
    animation: circGrow6 1.4s ease-out infinite;
    animation-delay: 0.8s;
  }
  .circ7 {
    transform: rotate(270deg) translateY(-50px);
    animation: circGrow7 1.4s ease-out infinite;
    animation-delay: 1s;
  }
  .circ8 {
    transform: rotate(315deg) translateY(-50px);
    animation: circGrow8 1.4s ease-out infinite;
    animation-delay: 1.2s;
  }
  
  @keyframes circGrow1 {
    0% { transform: translateY(-50px) scale(1); }
    50% { transform: translateY(-50px) scale(2); }
    100% { transform: translateY(-50px) scale(1); }
  }
  @keyframes circGrow2 {
    0% { transform: rotate(45deg) translateY(-50px) scale(1); }
    50% { transform: rotate(45deg) translateY(-50px) scale(2); }
    100% { transform: rotate(45deg) translateY(-50px) scale(1); }
  }
  @keyframes circGrow3 {
    0% { transform: rotate(90deg) translateY(-50px) scale(1); }
    50% { transform: rotate(90deg) translateY(-50px) scale(2); }
    100% { transform: rotate(90deg) translateY(-50px) scale(1); }
  }
  @keyframes circGrow4 {
    0% { transform: rotate(135deg) translateY(-50px) scale(1); }
    50% { transform: rotate(135deg) translateY(-50px) scale(2); }
    100% { transform: rotate(135deg) translateY(-50px) scale(1); }
  }
  @keyframes circGrow5 {
    0% { transform: rotate(180deg) translateY(-50px) scale(1); }
    50% { transform: rotate(180deg) translateY(-50px) scale(2); }
    100% { transform: rotate(180deg) translateY(-50px) scale(1); }
  }
  @keyframes circGrow6 {
    0% { transform: rotate(225deg) translateY(-50px) scale(1); }
    50% { transform: rotate(225deg) translateY(-50px) scale(2); }
    100% { transform: rotate(225deg) translateY(-50px) scale(1); }
  }
  @keyframes circGrow7 {
    0% { transform: rotate(270deg) translateY(-50px) scale(1); }
    50% { transform: rotate(270deg) translateY(-50px) scale(2); }
    100% { transform: rotate(270deg) translateY(-50px) scale(1); }
  }
  @keyframes circGrow8 {
    0% { transform: rotate(315deg) translateY(-50px) scale(1); }
    50% { transform: rotate(315deg) translateY(-50px) scale(2); }
    100% { transform: rotate(315deg) translateY(-50px) scale(1); }
  }