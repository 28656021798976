.account-information {
    border: 1px solid #e4e4e4;
    
}

.account-information__inner {
    padding: 25px 35px;
}

.account-information__main-information {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
}

@media(max-width: 576px){ 
    .account-information__main-information {
        right: 1rem;
    }
}