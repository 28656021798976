.content {
  margin-top: 0px;
  padding-top: -5px;
  font-size: 15px;
}

.DashBoardPage h6 {
  height: 22px;
  margin: 0px;
  font-weight: bold;
}

.small-header {
  height: 24px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.833333px;
  color: #19171a;
}

.main-header {
  font-weight: bold;
  font-size: 37px;
  line-height: 54px;
  letter-spacing: 1px;
  color: #19171a;
}

