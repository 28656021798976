.field {
  margin-top: 27px;
}

.field-text-label {
  font-size: 14px;
  color: #19171a;
  font-weight: bold;
  margin-bottom:11px;
}

.button-select {
  display: flex;
  font-family: "Scandia", Arial, sans-serif;
}

.button-select:hover {
  color: white;
}

.danger:focus {
  outline: none;
}

p {
  color: #1a171b;
  transition: 0.3s ease-out;
}

.button-select__option:hover {
  cursor: pointer;
  transition: 0.3s ease-out;
  background-color: #294952;
  border: 1px solid #060707;
  opacity: 0.6;
}

.button-select__label:hover {
  color: white;
}

a:hover {
  color: white;
}
.button-select__option p {
  color: #cc0066;
  font-size: 13px;
  font-weight: bold;
  margin-top: 16px;
}

.button-select__option {
  border: 0.5px solid #cc0066;
  color: #cc0066;
  text-align: center;
  transition: 0.2s ease-out;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  flex: auto !important;
  border-radius: 0;
  letter-spacing: 1px;
  font-family: "Scandia", Arial, sans-serif !important;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: none !important;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #294952;
  border-color: none;
}

.button-select__option.selected {
  background-color: #294952;
  border: 1px solid #294952;
  transition: 0.3s ease-out;
  color: white;
  outline: none;
}

.button-select__option.selected p {
  color: white;
  transition: 0.3s ease-out;
}

@media (max-width: 946px) {
  .button-select__option p {
    font-size: 13px;
  }
}

@media (max-width: 769px) {
  .button-select {
    display: block;
  }

  .button-select__option ~ .button-select__option {
    margin-top: 1rem;
  }
}

@media (max-width: 1284px) {
  .button-select__option p {
    font-size: 12px;
    margin: 14px 0 14px 0;
    padding: 5px;
  }
}
