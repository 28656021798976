.field {
  margin-top: 20px;
}

.field-text {
  font-size: 14px;
  color: #19171a;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.radio-button__option:hover {
  cursor: pointer;
}

.radio-button__label {
  font-weight: normal;
  color: #1a171b;
  font-size: 14px;
}

.radio-button__input-box {
  float: right;
  height: 13px;
  width: 13px;
  background: #fff;
  box-shadow: inset 0 0 0 2px #fff, 0 0 0 1px;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  top: 3px;
  transition: 0.3s;
}

.radio-button__option:hover .radio-button__input-box {
  background-color: #ffffff;
  -webkit-box-shadow: -1px 0px 10px 1px rgba(116, 116, 116, 1);
  -moz-box-shadow: -1px 0px 10px 1px rgba(116, 116, 116, 1);
  box-shadow: -1px 0px 10px 1px rgba(116, 116, 116, 1);
  border: 0.5px solid #19171a;
  transition: 0.3s;
}

.radio-button__option:hover .radio-button__input-box.selected {
  background-color: #cc0066;
  box-shadow: inset 0 0 0 2px #fff, 0 0 0 1px #c06;
  transition: 0.3s;
}

.radio-button__input-box.selected {
  background-color: #cc0066;
  box-shadow: inset 0 0 0 2px #fff, 0 0 0 1px #c06;
  transition: 0.3s;
}

.selected .radio-button__label {
  color: #c06;
  font-weight: bold;
  transition: 0.3s;
}

.radio-buttons.inline .radio-button__option:last-child {
  margin-right: 0px;
}

.radio-buttons.inline .radio-button__input-box {
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  float: none;
}

.radio-buttons.inline .radio-button__label {
  display: block;
  text-align: center;
}

@media (max-width: 1284px) {
  .radio-button__label {
    font-size: 16px;
  }
}

@media (max-width: 978px) {
  .radio-buttons.inline {
    margin-top: 0.25rem;
  }
}
