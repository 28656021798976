.date{
  left: 14px;
  top: 18px;
  font-family: Scandia;
  font-size: 16px;
}

.submitted{
  font-family: Scandia;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}
