.footer {
  width: 100%;
  min-height: 5vh;
  background-color: #294952;
  margin-top: 24px;
  color: #e4e4e4;
}

.footer__content {
  max-width: 75%;
  margin: auto;
}

.footer__link:not(:last-chil d):before {
  content: "";
  position: absolute;
  left: 100%;
  height: 60%;
  border-right: 2px solid white;
}

.footer__option {
  flex-basis: auto !important;
}
