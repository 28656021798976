.new-mortgage {
    border: 1px solid #e4e4e4;
}

.new-mortgage__inner {
    padding: 25px 35px 25px 35px;
    background-color: #294952;
}

.new-mortgage h5 {
    margin: 0;
    color: white;
}

.new-mortgage__bottom {
    background-color: #f4f4f4;
    padding: 0 35px 25px;
}
