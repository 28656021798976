.contact-us {
    background-color: #294952;
}

.contact-us h5, .contact-us p {
    color: white;
}

.contact-us__phone-number {
    font-size: 28px;
}

.contact-us__inner {
    padding: 25px 35px 25px 35px;
}

.contact-us__opening-hours-line {
    width: 100%;
    display: inline-block;
}

.contact-us__opening-hours-label {
    float: left;
    font-weight: bold;
}

.contact-us__opening-hours-value {
    float: right;
    font-weight: bold;
}

.contact-us__email-button {
    max-width: 75%;
}

@media(max-width: 567px){ 
    .contact-us__opening-hours-value {
        letter-spacing: 0;
    }
}

.contact-us__opening-hours-divider {
    height: 0px;
    width: 100%;
    border-bottom: 1px solid white;
    clear: both;
}

.contact-us__message {
    margin-top: 20px;
}

/* This is a fix for internet explorer to ensure the chevron button is aligned correctly */
.contact-us__email-button .button__main, .contact-us__email-button .button__next {
    position: static !important;
}
