.static-information-field {
    margin-top: 15px;
}

.static-information-field__label {
    color: #19171a;
    font-weight: bold;
    margin: 0;
}

.static-information-field__value {
    font-size: 18px;
    color: #1a171b;
    margin: 0;
    word-wrap: break-word;
}

.secondary .static-information-field__value {
    font-size: 22px;
    color: #19171a;
}

.static-information-field.inverted .static-information-field__label {
    color: white;
}

.static-information-field.inverted .static-information-field__value {
    color: white;
}
